import React, { useEffect, useState, useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MuiButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import AdminContext from "components/adminComponents/AdminContext";
import Button from "components/templatesComponents/Button";
import Icon from "components/templatesComponents/Icon";
import Modal from "components/templatesComponents/Modal";
import ModalConfirm from "components/adminComponents/ModalConfirm";
import AdminMessageContext from "components/adminComponents/AdminMessageContext";
import InputFilter from "components/adminComponents/InputFilter";
import EnhancedTable from "components/adminComponents/EnhancedTable";
import AdminGeoPointsService from "components/adminServices/AdminGeoPointsService";
import { emailRegex, latitudeRegex, longitudeRegex } from "utils/commonUtils";
import keycloak from "apps/back-office/client/keycloak";
import themes from "apps/front-office/client/themes/themes";
import SelectDepartment from "components/adminComponents/SelectDepartment";
import { exportDate } from "utils/contentsUtils";

const allStatus = {
  all: "all",
  active: "1",
  inactive: "0",
};

const formatDate = (date) => {
  return new Date(date).toLocaleDateString("FR-fr", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

const EasyGrid = (props) => {
  const { grid } = props;

  return grid.map((row) => (
    <Grid container alignItems="center" spacing={4}>
      {(Array.isArray(row) ? row : [row]).map((item) => (
        <Grid item xs>
          {item}
        </Grid>
      ))}
    </Grid>
  ));
};

EasyGrid.propTypes = {
  grid: PropTypes.arrayOf(PropTypes.oneOf[(PropTypes.arrayOf(PropTypes.element), PropTypes.element)]).isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  refreshIcon: {
    fontSize: "0.7rem",
    float: "right",
    display: "flex",
    alignItems: "center",
  },
  rotate: {
    animation: "$spin 2s linear infinite",
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: "#eff4fd",
    opacity: "0.5",
    zIndex: 1000,
  },
  endIcon: {
    position: "relative",
    "& > button": {
      position: "absolute",
      right: 6,
      padding: "2px",
      marginTop: "18px",
    },
  },
  status: {
    alignItems: "center",
    "& .MuiFormLabel-root": {
      marginRight: "12px",
      fontSize: "1rem",
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "1rem",
    },
    "& .MuiFormControlLabel-root": {
      marginRight: "12px",
    },
    "& .MuiRadio-root": {
      padding: "4px",
      paddingLeft: "8px",
    },
  },
}));

const GeoPointForm = (props) => {
  const { geoPoint, onSubmit, submitLabel, onCancel, type, getGeoPoints } = props;

  const {
    others = {},
    isActive = false,
    customId,
    latitude,
    longitude,
    created,
    creator,
    updated,
    editor,
    category,
  } = geoPoint;

  const [submitted, setSubmitted] = useState(false);

  const [deleteGeoPointModal, setDeleteGeoPointModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const { currentSite } = useContext(AdminContext);

  const classes = useStyles();

  const theme = themes[currentSite.name] || themes.ANTS;

  const handleValidate = useCallback((values) => {
    const { email, latitude: lat, longitude: long, customId: id, name } = values;
    const errors = {};
    if (email && !email.match(emailRegex)) {
      errors.email = "Format d'email incorrect";
    }
    if (lat && !lat.match(latitudeRegex)) {
      errors.latitude = "Latitude incorrecte. (Doit se situer entre -90 et 90 au format xx.xxx)";
    }
    if (long && !long.match(longitudeRegex)) {
      errors.longitude = "Longitude incorrecte. (Doit se situer entre -180 et 180 au format xx.xxx)";
    }
    if (!lat) {
      errors.latitude = "Champs requis";
    }
    if (!long) {
      errors.longitude = "Champs requis";
    }
    if (!id) {
      errors.customId = "Champs requis";
    }
    if (!name) {
      errors.name = "Champs requis";
    }
    return errors;
  }, []);

  const closeDeleteGeoPointModal = () => {
    setDeleteGeoPointModal(false);
  };

  const deleteGeopoint = () => {
    setLoader(true);
    AdminGeoPointsService.update({ id: geoPoint.id, isActive: false })
      .then((inactiveGeoPoint) => AdminGeoPointsService.delete(inactiveGeoPoint))
      .then(() => {
        displaySuccess("Établissement supprimé");
        getGeoPoints();
        setLoader(false);
      })
      .catch(() => {
        displayError("Échec lors de la suppression de l'établissement");
        setLoader(false);
      });
  };

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      const { isActive: active, customId: id, latitude: lat, longitude: long, category: cat, ...rest } = values;
      const submitPromise = onSubmit({
        id: geoPoint.id,
        isActive: active,
        customId: id,
        latitude: lat,
        longitude: long,
        category: cat,
        type,
        others: { customId: id, category: cat, ...rest },
      });
      if (submitPromise && submitPromise.then) {
        setSubmitting(true);
        submitPromise.then(() => setSubmitting(false));
      }
    },
    [geoPoint.id, onSubmit, type]
  );

  const categories = useMemo(() => {
    switch (type) {
      case "photographe":
        return [
          { value: "photographe", label: "Photographe" },
          { value: "cabine", label: "Cabine" },
        ];
      case "mairie":
        return [
          { value: "passeport", label: "Passeport" },
          { value: "certification", label: "Certification" },
        ];
      default:
        return [{}, {}];
    }
  }, [type]);

  return (
    <Box p={2} borderColor="grey.500" border={1} style={{ position: "relative" }}>
      <Formik
        initialValues={{
          ...others,
          isActive,
          customId,
          latitude,
          longitude,
          created,
          creator,
          updated,
          editor,
          category,
        }}
        validate={handleValidate}
        onSubmit={handleFormSubmit}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form
            onSubmit={(e) => {
              setSubmitted(true);
              handleSubmit(e);
            }}
          >
            {(() => {
              const createInput = (name, label, extraProps = {}) => {
                const value = ["created", "updated"].includes(name) ? formatDate(values[name]) : values[name];
                return (
                  <TextField
                    error={submitted && !!errors[name]}
                    label={label}
                    value={value}
                    name={name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={submitted && errors[name]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...extraProps}
                  />
                );
              };
              const cityRow = [createInput("postalCode", "Code postal"), createInput("city", "Ville")];
              const addressRow =
                type && type === "siv"
                  ? [
                      <EasyGrid
                        grid={[[createInput("numVoie", "N° de voie"), createInput("typeVoie", "Type de voie")]]}
                      />,
                      createInput("libelleVoie", "Libellé de voie"),
                    ]
                  : [createInput("address", "Adresse")];
              const specificFields = [
                type && type !== "siv" ? (
                  <TextField
                    id="category"
                    name="category"
                    select
                    label="Type"
                    value={values.category?.toLowerCase() || ""}
                    onChange={handleChange}
                  >
                    {categories.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null,
              ];
              if (type && type === "photographe") {
                specificFields.splice(
                  1,
                  0,
                  <FormControlLabel
                    control={<Checkbox name="ePhoto" checked={values.ePhoto} onChange={handleChange} disableRipple />}
                    label="e-Photo"
                  />
                );
              }
              const otherFields = [
                <EasyGrid grid={[[createInput("latitude", "Latitude*"), createInput("longitude", "Longitude*")]]} />,
                <EasyGrid grid={[specificFields]} />,
              ];

              if (type && type !== "siv") {
                otherFields.splice(1, 0, createInput("address2", "Complément d'adresse"));
              }

              const grid = [
                [
                  createInput("name", "Dénomination*"),
                  <EasyGrid
                    grid={[
                      [
                        createInput("customId", "Identifiant*"),
                        <EasyGrid
                          grid={[
                            [
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="isActive"
                                    checked={values.isActive}
                                    onChange={handleChange}
                                    disableRipple
                                  />
                                }
                                label="Actif"
                              />,
                              geoPoint.id ? (
                                <IconButton
                                  onClick={() => setDeleteGeoPointModal(true)}
                                  aria-label="Supprimer"
                                  size="small"
                                >
                                  <Icon icon="trash-alt" type="fas" iconDSFR="delete-line" title="Supprimer" />
                                </IconButton>
                              ) : null,
                            ],
                          ]}
                        />,
                      ],
                    ]}
                  />,
                ],
                addressRow.concat(cityRow),
                otherFields,
                [
                  createInput("phone", "Téléphone"),
                  createInput("email", "Email"),
                  createInput("webSite", "Site internet"),
                ],
                [
                  createInput("openingTimeMonday", "Lundi"),
                  createInput("openingTimeTuesday", "Mardi"),
                  createInput("openingTimeWednesday", "Mercredi"),
                  createInput("openingTimeThursday", "Jeudi"),
                  createInput("openingTimeFriday", "Vendredi"),
                  createInput("openingTimeSaturday", "Samedi"),
                  createInput("openingTimeSunday", "Dimanche"),
                ],
                [
                  createInput("created", "Créé le", { disabled: true }),
                  createInput("creator", "Créé par", { disabled: true }),
                  createInput("updated", "Mis à jour le", { disabled: true }),
                  createInput("editor", "Mis à jour par", { disabled: true }),
                ],
              ];
              return <EasyGrid grid={grid} />;
            })()}
            <Box mt={2}>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  {typeof onCancel === "function" && (
                    <MuiButton
                      variant="contained"
                      color="default"
                      type="button"
                      size="large"
                      disabled={isSubmitting}
                      onClick={onCancel}
                    >
                      Annuler
                    </MuiButton>
                  )}
                </Grid>
                <Grid item>
                  <Button primary type="submit" disabled={isSubmitting}>
                    {submitLabel}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
      {loader && (
        <Grid container justifyContent="center" alignItems="center" className={classes.loader}>
          <CircularProgress />
        </Grid>
      )}
      <Modal open={deleteGeoPointModal} size="sm" onClose={closeDeleteGeoPointModal}>
        <ModalConfirm
          title={`Supprimer "${geoPoint?.others?.name}"`}
          text={
            <Box pb={2}>
              Attention cette action est irréversible.
              <br />
              L&apos;établissement pourra être réimporté via un csv ou recréé manuellement.
            </Box>
          }
          confirmText="Supprimer"
          confirmButtonProps={{ style: { backgroundColor: theme.palette.error.main } }}
          onConfirm={() => {
            deleteGeopoint();
            closeDeleteGeoPointModal();
          }}
          onCancel={closeDeleteGeoPointModal}
        />
      </Modal>
    </Box>
  );
};

GeoPointForm.propTypes = {
  geoPoint: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  onCancel: PropTypes.func,
  type: PropTypes.string.isRequired,
  getGeoPoints: PropTypes.func,
};

GeoPointForm.defaultProps = {
  geoPoint: {},
  submitLabel: "Valider",
  onCancel: null,
  getGeoPoints: null,
};

let searchTimeout = null;

const AdminGeoPoints = (props) => {
  const { type } = props;

  const classes = useStyles();

  const categories = useMemo(() => {
    switch (type) {
      case "photographe":
        return { basic: "Photographe", specific: "Cabine" };
      case "mairie":
        return { basic: "Passeport", specific: "Certification" };
      default:
        return {};
    }
  }, [type]);

  const [uploaderId] = useState(`uploader-${uuidv4()}`);
  const [onUploadFile, setOnUploadFile] = useState(false);
  const [onExport, setOnExport] = useState(false);

  const [geoPoints, setGeoPoints] = useState({
    data: [],
    total: 0,
  });
  const [sorting, setSorting] = useState({
    order: "DESC",
    orderBy: "updated",
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [inputIdentValue, setInputIdentValue] = useState("");
  const [identifiant, setIdentifiant] = useState("");
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("all");
  const [specificType, setSpecificType] = useState("all");
  const [department, setDepartment] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [displaySearch, setDisplaySearch] = useState(false);

  const { order, orderBy } = sorting;

  const [createGeoPointModal, setCreateGeoPointModal] = useState(false);

  const { displaySuccess, displayError } = useContext(AdminMessageContext);

  const filter = useMemo(() => {
    const newFilters = [`type||eq||${type}`];
    if (search) {
      // Pas beau mais 'others' est un champ string côté API
      // La seule autre solution serait de brancher le tableau à l'elastic search
      newFilters.push(`others||cont||${search}`);
    }
    if (identifiant) {
      newFilters.push(`customId||cont||${identifiant}`);
    }
    if (status !== "all") {
      newFilters.push(`isActive||eq||${Number(allStatus[status])}`);
    }
    if (specificType !== "all") {
      newFilters.push(`category||eq||${categories[specificType].toLowerCase()}`);
    }
    if (department) {
      newFilters.push(`others||cont||"postalCode":"${department.code}`);
    }
    return newFilters;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, search, identifiant, status, specificType, department]);

  const getGeoPoints = useCallback(() => {
    const options = {
      params: {
        filter,
        sort: [`${orderBy},${order}`],
        per_page: rowsPerPage,
        offset: (page - 1) * rowsPerPage,
      },
    };
    AdminGeoPointsService.find(options).then((geoPointsResults) => {
      const { data, total } = geoPointsResults;
      setGeoPoints({ data, total });
    });
  }, [filter, orderBy, order, rowsPerPage, page]);

  let customId = "";
  switch (type) {
    case "mairie":
      customId = "Code UGF";
      break;
    case "photographe":
      customId = "Agrément";
      break;
    case "siv":
      customId = "Habilitation";
      break;
    default:
      customId = "Identifiant";
  }

  const columns = [
    {
      id: "actions",
      label: "",
      cellProps: { style: { width: "1px" } },
      render: () => <Icon icon="edit" iconDSFR="edit-box-line" title="Modifier" />,
    },
    {
      id: "customId",
      label: customId,
      render: (geoPoint) => geoPoint.customId,
    },
    {
      id: "name",
      label: "Dénomination",
      render: (geoPoint) => geoPoint.others && geoPoint.others.name,
    },
    {
      id: "address",
      label: "Adresse",
      render: (geoPoint) => geoPoint.others && geoPoint.others.address,
    },
    {
      id: "postalCode",
      label: "CP",
      render: (geoPoint) => geoPoint.others && geoPoint.others.postalCode,
    },
    {
      id: "city",
      label: "Ville",
      render: (geoPoint) => geoPoint.others && geoPoint.others.city,
    },
    {
      id: "updated",
      label: "Édité le",
      sortable: true,
      render: (geoPoint) => formatDate(geoPoint.updated || geoPoint.created),
    },
    {
      id: "isActive",
      label: "Actif",
      sortable: true,
      render: (geoPoint) => (
        <Icon
          icon="circle"
          type="fas"
          iconDSFR={geoPoint.isActive ? "checkbox-circle-fill" : "close-circle-fill"}
          style={{ color: geoPoint.isActive ? "green" : "red" }}
          title={geoPoint.isActive ? "Actif" : "Inactif"}
          aria-label={geoPoint.isActive ? "Actif" : "Inactif"}
        />
      ),
    },
  ];

  useEffect(() => {
    getGeoPoints();
  }, [getGeoPoints]);

  const handleChangeSort = (event, key) => {
    if (orderBy !== key) {
      setSorting({
        order: "ASC",
        orderBy: key,
      });
    } else {
      setSorting({
        order: order === "ASC" ? "DESC" : "ASC",
        orderBy: key,
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleIdentifiantChange = (event) => {
    const { value } = event.target;
    setInputIdentValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      setIdentifiant(value);
    }, 350);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setInputSearchValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      searchTimeout = null;
      setSearch(value);
    }, 350);
  };

  const handleResetSearch = () => {
    setInputSearchValue("");
    setSearch("");
  };

  const handleResetIdentifiant = () => {
    setInputIdentValue("");
    setIdentifiant("");
  };

  const handleSubmitGeoPoint = (callback) => (geoPoint) => {
    const onSuccess = () => {
      getGeoPoints();
      if (typeof callback === "function") {
        callback();
      }
    };
    if (geoPoint.id) {
      return AdminGeoPointsService.update(geoPoint)
        .then(() => {
          displaySuccess("Établissement mis à jour");
          onSuccess();
        })
        .catch((e) => {
          if (e?.response?.data?.detail?.includes("Duplicate entry")) {
            displayError("L'identifiant est déjà utilisé pour un autre établissement");
          } else {
            displayError("Échec lors de la mise à jour de l'établissement");
          }
        });
    }
    return AdminGeoPointsService.create(geoPoint)
      .then(() => {
        displaySuccess("Établissement créé");
        onSuccess();
      })
      .catch((e) => {
        if (e?.response?.data?.detail?.includes("Duplicate entry")) {
          displayError("L'identifiant est déjà utilisé pour un autre établissement");
        } else {
          displayError("Échec lors de la création de l'établissement");
        }
      });
  };

  const handleClickAddGeoPoint = () => {
    setCreateGeoPointModal(true);
  };

  const closeCreateGeoPointModal = () => {
    setCreateGeoPointModal(false);
  };

  const handleClickUpload = () => {
    document.getElementById(uploaderId).click();
  };

  const handleUploadFile = (e) => {
    const [file] = e.target.files;
    let jsonFile = null;
    if (keycloak?.idTokenParsed) {
      jsonFile = JSON.stringify({
        userId: keycloak?.idTokenParsed?.sub,
        fullName: keycloak?.idTokenParsed?.preferred_username,
      });
    }
    if (file && jsonFile) {
      setOnUploadFile(true);
      AdminGeoPointsService.import({ file, type, jsonFile })
        .then(() => {
          displaySuccess("Fichier importé");
          document.getElementById(uploaderId).value = null;
          setOnUploadFile(false);
        })
        .catch((err) => {
          const message = err && err.response && err.response.data;
          displayError(`Échec lors de l'import du fichier ${message && ` : ${message}`}`);
          document.getElementById(uploaderId).value = null;
          setOnUploadFile(false);
        });
    }
  };

  const handleExport = () => {
    setOnExport(true);
    const params = {
      type,
      filter,
    };
    AdminGeoPointsService.export(params)
      .then((response) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.setAttribute("download", `Export_${type}_${exportDate}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setOnExport(false);
      })
      .catch(() => {
        displayError("Échec lors de l'export de la liste");
        setOnExport(false);
      });
  };

  const hasCategories = categories && Object.keys(categories).length > 0;

  const menuItems = [
    {
      label: "Importer un .csv",
      icon: "upload-line",
      onClick: handleClickUpload,
      disabled: onUploadFile,
    },
    {
      label: "Exporter la liste",
      icon: "download-line",
      onClick: handleExport,
      disabled: onExport || geoPoints.total === 0,
    },
    {
      label: "Ajouter un établissement",
      icon: "add-line",
      onClick: handleClickAddGeoPoint,
      disabled: false,
    },
  ];

  const nextStatus = useCallback((answer) => {
    const answerShifted = {
      all: "active",
      active: "inactive",
      inactive: "all",
    };
    return setStatus(answerShifted[answer]);
  }, []);

  const nextType = useCallback((answer) => {
    const answerShifted = {
      all: "basic",
      basic: "specific",
      specific: "all",
    };
    return setSpecificType(answerShifted[answer]);
  }, []);

  const searchReinit = () => {
    setIdentifiant("");
    setInputIdentValue("");
    setStatus("all");
    setSpecificType("all");
    setDepartment("");
    setSearch("");
    setInputSearchValue("");
  };

  return (
    <div className={classes.root}>
      <input type="file" accept=".csv" id={uploaderId} onChange={handleUploadFile} style={{ display: "none" }} />
      <h6>Liste des établissements ({type})</h6>
      <Box mb={2}>
        <Grid container justifyContent="space-between" spacing={2} alignItems={displaySearch ? "center" : "flex-end"}>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item container alignItems="flex-end" spacing={3}>
              <Grid item xs={3} className={classes.endIcon}>
                <InputFilter
                  label="Par identifiant"
                  placeholder={customId}
                  inputValue={inputIdentValue}
                  handleChange={handleIdentifiantChange}
                  handleReset={handleResetIdentifiant}
                />
              </Grid>
              <Grid item xs={4} container alignItems="center">
                <Typography style={{ marginRight: "16px" }}>Statut :</Typography>
                {status === "all" && (
                  <MuiButton size="large" onClick={() => nextStatus(status)}>
                    Tous
                  </MuiButton>
                )}
                {status === "active" && (
                  <MuiButton size="large" onClick={() => nextStatus(status)} style={{ color: "green" }}>
                    Actif
                  </MuiButton>
                )}
                {status === "inactive" && (
                  <MuiButton size="large" onClick={() => nextStatus(status)} style={{ color: "red" }}>
                    Inactif
                  </MuiButton>
                )}
              </Grid>
              {hasCategories && (
                <Grid item xs={4} container alignItems="center">
                  <Typography style={{ marginRight: "16px" }}>Type :</Typography>
                  {specificType === "all" && (
                    <MuiButton size="large" onClick={() => nextType(specificType)}>
                      Tous
                    </MuiButton>
                  )}
                  {specificType === "basic" && (
                    <MuiButton size="large" onClick={() => nextType(specificType)} style={{ color: "#396FF1" }}>
                      {categories[specificType]}
                    </MuiButton>
                  )}
                  {specificType === "specific" && (
                    <MuiButton size="large" onClick={() => nextType(specificType)} style={{ color: "#F32121" }}>
                      {categories[specificType]}
                    </MuiButton>
                  )}
                </Grid>
              )}
              <Grid item xs={1} lg style={{ textAlign: "end" }}>
                <IconButton size="small" onClick={() => setDisplaySearch(!displaySearch)}>
                  <Icon
                    iconDSFR={displaySearch ? "arrow-up-s-line" : "arrow-down-s-line"}
                    title={displaySearch ? "Masquer la recherche" : "Affiner la recherche"}
                  />
                </IconButton>
              </Grid>
            </Grid>
            {displaySearch && (
              <Grid item container spacing={2}>
                <Grid item xs={6} lg={6}>
                  <SelectDepartment department={department} setDepartment={setDepartment} />
                </Grid>
                <Grid item xs={6} lg={6} className={classes.endIcon}>
                  <InputFilter
                    label="Filtrer les établissements"
                    placeholder="Dénomination, adresse, etc..."
                    inputValue={inputSearchValue}
                    handleChange={handleSearchChange}
                    handleReset={handleResetSearch}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={1} style={{ textAlign: "center" }}>
            <IconButton
              aria-label="actions"
              aria-controls="menu-actions"
              aria-haspopup="true"
              size="small"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <Icon
                iconDSFR="more-fill"
                title="Actions"
                aria-label="Menu d'actions"
                style={{ transform: "rotate(90deg)" }}
              />
            </IconButton>
          </Grid>
          <Menu
            id="menu-actions"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {menuItems.map((item) => {
              const { label, icon, onClick, disabled } = item;
              return (
                <MenuItem
                  key={label}
                  disabled={disabled}
                  onClick={() => {
                    onClick();
                    setAnchorEl(null);
                  }}
                >
                  <Icon iconDSFR={icon} />
                  <Typography style={{ marginLeft: "16px" }}>{label}</Typography>
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
      </Box>
      <div style={{ flex: "1 0 auto" }}>
        {geoPoints.total === 0 && (search || status !== "all" || specificType !== "all") ? (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ height: "100%" }}
            spacing={3}
          >
            <Grid item>
              <p>Aucun établissement</p>
            </Grid>
            <Grid item>
              <button type="button" className="fr-link" onClick={() => searchReinit()}>
                Réinitialiser la recherche
              </button>
            </Grid>
          </Grid>
        ) : (
          <EnhancedTable
            columns={columns}
            data={geoPoints.data}
            order={order}
            orderBy={orderBy}
            page={page}
            totalRows={geoPoints.total}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50]}
            onRequestSort={handleChangeSort}
            onChangePage={setPage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            renderRowDetail={(row, closeDetail) => (
              <GeoPointForm
                geoPoint={row}
                onSubmit={handleSubmitGeoPoint(closeDetail)}
                onCancel={closeDetail}
                submitLabel="Mettre à jour"
                type={type}
                getGeoPoints={getGeoPoints}
              />
            )}
          />
        )}
      </div>
      <Modal open={createGeoPointModal} size="lg" onClose={closeCreateGeoPointModal}>
        <h2>Nouvel établissement (type {type})</h2>
        <GeoPointForm
          onSubmit={handleSubmitGeoPoint(closeCreateGeoPointModal)}
          onCancel={closeCreateGeoPointModal}
          submitLabel="Créer"
          type={type}
        />
      </Modal>
    </div>
  );
};

AdminGeoPoints.propTypes = {
  type: PropTypes.string.isRequired,
};

export default AdminGeoPoints;
