import React, { Fragment } from "react";
import PropTypes from "prop-types";
import IconForm from "components/adminComponents/contentForms/IconForm";
import InputForm from "components/adminComponents/contentForms/InputForm";
import SelectForm from "components/adminComponents/contentForms/SelectForm";
import CheckboxForm from "components/adminComponents/contentForms/CheckboxForm";
import AdminListForm from "components/adminComponents/contentForms/AdminListForm";
import elementsTypes from "utils/elementsTypes";
import CKEditor from "ckeditor4-react";
import { standardToolbarEditor } from "utils/commonUtils";
import Tabs from "components/templatesComponents/Tabs";

CKEditor.editorUrl = "/ckeditor/ckeditor.js";

const SiteSettingsForm = (props) => {
  const { content, classes, onContentChange } = props;
  const { id, label, children = [] } = content;

  const warningBannerChild = children.find((c) => c.key === "warningBanner") || {};
  const analyticsChild = children.find((c) => c.key === "analytics") || {};
  const socialNetworkListChild = children.filter((c) => c.key === "socialNetworks");

  const handleChildChange = (oldChild, newChild) => {
    onContentChange({
      ...content,
      children: children.map((c) => (c === oldChild ? newChild : c)),
    });
  };

  const handleChildrenChange = (oldChildren, newChildren) => {
    onContentChange({
      ...content,
      children: [...children.filter((c) => !oldChildren.map((oldChild) => oldChild.id).includes(c.id)), ...newChildren],
    });
  };

  return (
    <div key={id}>
      <h2 className={classes.title}>{label}</h2>

      <Tabs
        navigate={false}
        tabs={[
          {
            title: "Informations",
            children: (
              <>
                <div className={classes.fieldset}>
                  <h5>{warningBannerChild.label}</h5>

                  <CheckboxForm
                    content={{
                      ...(warningBannerChild.children || []).find((c) => c.key === "enable"),
                      label: "Afficher le bandeau d'information",
                    }}
                    onContentChange={(newContent) =>
                      handleChildChange(warningBannerChild, {
                        ...warningBannerChild,
                        children: (warningBannerChild.children || []).map((c) => (c.key === "enable" ? newContent : c)),
                      })
                    }
                    classes={classes}
                  />
                  {(warningBannerChild.children || []).find((c) => c.key === "enable")?.value === "true" && (
                    <Fragment>
                      <CKEditor
                        onBeforeLoad={(CKEDITOR) => {
                          // eslint-disable-next-line no-param-reassign
                          CKEDITOR.disableAutoInline = true;
                        }}
                        key={((warningBannerChild.children || []).find((c) => c.key === "message") || {}).value.id}
                        onChange={(e) =>
                          handleChildChange(warningBannerChild, {
                            ...warningBannerChild,
                            children: (warningBannerChild.children || []).map((c) =>
                              c.key === "message" ? { ...c, value: encodeURIComponent(e.editor.getData()) } : c
                            ),
                          })
                        }
                        data={decodeURIComponent(
                          ((warningBannerChild.children || []).find((c) => c.key === "message") || {}).value
                        )}
                        config={{ toolbar: standardToolbarEditor }}
                      />
                      <SelectForm
                        content={(warningBannerChild.children || []).find((c) => c.key === "severity")}
                        onContentChange={(newContent) =>
                          handleChildChange(warningBannerChild, {
                            ...warningBannerChild,
                            children: (warningBannerChild.children || []).map((c) =>
                              c.key === "severity" ? newContent : c
                            ),
                          })
                        }
                        classes={classes}
                      />
                    </Fragment>
                  )}
                </div>

                <div className={classes.fieldset}>
                  <h5>Analytics</h5>
                  <InputForm
                    content={{ ...analyticsChild, label: "Identifiant de suivi" }}
                    onContentChange={(newContent) => handleChildChange(analyticsChild, newContent)}
                    classes={classes}
                  />
                </div>
              </>
            ),
          },
          {
            title: "Réseaux sociaux",
            children: (
              <div className={classes.fieldset}>
                <AdminListForm
                  inline
                  contents={socialNetworkListChild}
                  elementsType={elementsTypes.SOCIAL_NETWORK}
                  elementsKey="socialNetworks"
                  onContentsChange={(newContents) => handleChildrenChange(socialNetworkListChild, newContents)}
                >
                  {socialNetworkListChild.map((socialNetwork) => (
                    <div key={socialNetwork.id} className={classes.fieldset}>
                      <InputForm
                        content={socialNetwork.children.find((c) => c.key === "url")}
                        onContentChange={(newContent) =>
                          handleChildChange(socialNetwork, {
                            ...socialNetwork,
                            children: (socialNetwork.children || []).map((c) => (c.key === "url" ? newContent : c)),
                          })
                        }
                        classes={classes}
                      />
                      <IconForm
                        content={socialNetwork.children.find((c) => c.key === "icon")}
                        onContentChange={(newContent) =>
                          handleChildChange(socialNetwork, {
                            ...socialNetwork,
                            children: (socialNetwork.children || []).map((c) => (c.key === "icon" ? newContent : c)),
                          })
                        }
                        classes={classes}
                      />
                    </div>
                  ))}
                </AdminListForm>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

SiteSettingsForm.propTypes = {
  content: PropTypes.shape().isRequired,
  classes: PropTypes.shape().isRequired,
  onContentChange: PropTypes.func.isRequired,
};

export default SiteSettingsForm;
